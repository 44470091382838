<template>
    <div>
        <s-form @submit="addItem">
            <h2 class="head font-poppins-semibold fs-lg-4 mt-3 text-primary">Vaccination</h2>
            <div class="row mt-4">
                <div class="col-lg-2">
                    <validated-ajax-vue-select label="Vaccinataion Type"
                                               :url="vaccinationOptionURL"
                                               class="c-input-select text-black field-required" :rules="{required:true}"
                                               v-model="model.vaccination_type"></validated-ajax-vue-select>
                </div>
                <div class="col-lg-2">
                    <validated-date-picker format="DD-MM-YYYY"
                                           label="Vaccination Date" class="text-black c-input-datepicker field-required" :rules="rules.FututreValidation"
                                           v-model="model.vaccination_date"></validated-date-picker>
                </div>
                <div class="col-lg-2">
                    <validated-date-picker format="DD-MM-YYYY" :rules="rules.common"
                                           label="Next Due Date" class="text-black c-input-datepicker field-required" v-model="model.next_due_date"></validated-date-picker>
                </div>
                <div class="col-lg-2">
                    <validated-checkbox label="Seromonitoring (Post)" class="pb-0 mb-xl-n4 text-black label-font-sm--1 mt-n1" v-model="model.seromonitoring"></validated-checkbox>
                    <validated-input></validated-input>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-lg-2">
                    <validated-ajax-vue-select label="Shed" :rules="{required:true}"
                                               :url="shedOptionURL" @input="loadBulls"
                                               class="c-input-select text-black field-required" v-model="model.shed_number"></validated-ajax-vue-select>
                </div>
                <div class="col-lg-2">
                    <validated-vue-select label="Animal" multiple
                                          :options="bullOption"
                                          class="c-input-select-multiplevalue text-black field-required" v-model="model.bull" :rules="{required:true}"></validated-vue-select>
                </div>
                <div class="col-lg-2">
                    <validated-input label="Vaccine Name" class="text-black field-required" :rules="{required:true}" v-model="model.vaccination_name"></validated-input>
                </div>
                <div class="col-lg-2">
                    <validated-input label="Vaccine Batch No" class="text-black field-required" v-model="model.vaccination_batch_no" :rules="{required:true}"></validated-input>
                </div>
                <div class="col-lg-2">
                    <validated-input :rules="{required:true}"
                                     label="Remarks" class="text-black field-required" v-model="model.remarks"></validated-input>
                </div>
            </div>
            <div class="column fl-x-br pt-9">
                <btn text="Save" :loading="loading" loading-text="Saving..." class="px-4"></btn>
                <btn class="ml-2" design="basic-b" type="reset" @click="BackToHome" text="Cancel"></btn>
            </div>
        </s-form>
    </div>
</template>

<script>
import masterURLs from '../../data/masterURLs';
import urls from '../../data/urls';
import axios from 'secure-axios';

export default {
    name: 'Vaccination',
    data () {
        return {

            vaccinationOptionURL: masterURLs.master.VaccinationType.vueSelect,
            shedOptionURL: masterURLs.master.shed.vueSelect + '?animal=bull',
            bullOption: [],
            loading: false,
            URL: urls.vaccination.addEdit,

            model: {
                vaccination_type: '',
                vaccination_date: '',
                bull: '',
                next_due_date: '',
                seromonitoring: '',
                vaccination_name: '',
                vaccination_batch_no: '',
                remarks: '',
                shed_number: ''

            },
            rules: {
                common: {
                    required: true,
                    customValidator: (value) => {
                        return this.Validation(value);
                    }
                },
                FututreValidation: {
                    required: true,
                    customValidator: (value) => {
                        return this.ValidationFuture(value);
                    }
                }
                /*   date: {
                    required: true,
                    customValidator: (value) => {
                        return this.DateValidation(value);
                    }
                } */
            }
        };
    },
    methods: {
        Validation (date) {
            const vacc = this.model.vaccination_date;
            const dateParts = date.split('-');
            if (dateParts.length !== 3) {
                return 'Please enter a valid AI date in the format DD-MM-YYYY.';
            }
            const aiDay = parseInt(dateParts[0], 10);
            const aiMonth = parseInt(dateParts[1], 10);
            const aiYear = parseInt(dateParts[2], 10);

            const vaccParts = vacc.split('-');
            if (vaccParts.length !== 3) {
                return 'Please enter a valid DOB in the format DD-MM-YYYY.';
            }
            const vaccDay = parseInt(vaccParts[0], 10);
            const vaccMonth = parseInt(vaccParts[1], 10);
            const vaccYear = parseInt(vaccParts[2], 10);

            if (isNaN(aiDay) || isNaN(aiMonth) || isNaN(aiYear) || isNaN(vaccDay) || isNaN(vaccMonth) || isNaN(vaccYear)) {
                return 'Please enter valid dates in the format DD-MM-YYYY.';
            }

            const dateObj = new Date(aiYear, aiMonth - 1, aiDay);
            const vaccDateObj = new Date(vaccYear, vaccMonth - 1, vaccDay);

            if (dateObj <= vaccDateObj) {
                return 'Date should be after the date of Vaccination Date.';
            }
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            if (dateObj < currentDate) {
                return 'Please enter a date from today onwards.';
            }
            return true;
        },
        /* DateValidation (value) {
            const parts = value.split('-');
            if (parts.length !== 3) {
                return 'Please enter a date in the format DD-MM-YYYY.';
            }
            const day = parseInt(parts[0], 10);
            const month = parseInt(parts[1], 10);
            const year = parseInt(parts[2], 10);

            if (isNaN(day) || isNaN(month) || isNaN(year)) {
                return 'Please enter a valid date in the format DD-MM-YYYY.';
            }
            const EDate = new Date(year, month - 1, day);

            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);

            if (EDate < currentDate) {
                return 'Please enter a date from today onwards.';
            }

            return true;
        }, */
        BackToHome () {
            this.$router.push({ path: '/semen-station/' });
        },
        ValidationFuture (date) {
            const dateParts = date.split('-');
            if (dateParts.length !== 3) {
                return 'Please enter a valid AI date in the format DD-MM-YYYY.';
            }
            const aiDay = parseInt(dateParts[0], 10);
            const aiMonth = parseInt(dateParts[1], 10);
            const aiYear = parseInt(dateParts[2], 10);
            if (isNaN(aiDay) || isNaN(aiMonth) || isNaN(aiYear)) {
                return 'Please enter valid dates in the format DD-MM-YYYY.';
            }

            const dateObj = new Date(aiYear, aiMonth - 1, aiDay);
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);

            if (dateObj > currentDate) {
                return 'Please enter a date from today onwards.';
            }
            return true;
        },

        async loadBulls () {
            const response = await axios.get(urls.vaccination.animalSelect + '?shed_number=' + this.model.shed_number);
            this.bullOption = response.data.data;
        },
        async addItem () {
            try {
                this.loading = true;
                const response = await axios.form(this.URL, this.model);
                const result = response.data;
                if (result.success) {
                    this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    await this.$router.push({ path: '/semen-station/' });
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            let errorMessage = '';
                            if (key === '__all__') {
                                errorMessage = `${errors[key]}`;
                            } else {
                                errorMessage = `${key}:  ${errors[key]}`;
                            }
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
    }

};
</script>

<style scoped>
</style>
